<template>
  <!-- 更改密码页 -->
  <div class="CP">
    <div class="CPlogo">
      <img class="logol" src="./img/logoCom.svg" alt="" />
      <span>
        <!-- 更改密码 -->
        {{ $t("label.account.Change.Password2") }}
      </span>
    </div>
    <div class="CPform">
      <div>
        <span>
          <!-- 基于安全原因，您现在必须更改密码 -->
          {{ $t("label.account.change.your.password") }}
        </span>
        <div>
          <!-- 用户名 -->
          {{ $t("label.account.username") }} {{ loginName }}
        </div>
      </div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item
          v-if="this.modifyPwType != 'autoRegister'"
          prop="oldPass"
          placeholder=""
        >
          <!-- 注册用户隐藏旧密码 -->
          <el-input
            v-model="ruleForm.oldPass"
            type="password"
            :placeholder="$t('label.account.oldPass')"
          />
          <span style="position: absolute; top: 4px; left: 7px; color: red"
            >*</span
          >
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            type="password"
            :placeholder="$t('label.account.New.Password1')"
          />
          <span style="position: absolute; top: 4px; left: 7px; color: red"
            >*</span
          >
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            v-model="ruleForm.checkPass"
            type="password"
            :placeholder="$t('label.account.Confirm.password')"
          />
          <span style="position: absolute; top: 4px; left: 7px; color: red"
            >*</span
          >
        </el-form-item>
      </el-form>
      <span>
        *
        {{
          $t("label.account.pswComplexReqRules", {
            0: minPswLength,
            1: pswComplexReqRules,
          })
        }}
        <!-- * 密码最小长度为{{ minPswLength }}个字符，且{{ pswComplexReqRules }} -->
      </span>
      <div class="Btn">
        <div />
        <el-button type="primary" @click="submitForm('ruleForm')">
          <!-- 重置 -->
          {{ $t("label.account.Reset") }}
        </el-button>
      </div>
    </div>
    <!--绑定MFA-->
    <enableMFA ref="enableMFA"></enableMFA>
    <!--验证MFA-->
    <validationMFA ref="validationMFA"></validationMFA>
  </div>
</template>

<script>
import * as loginApi from "../login/api";
import md5 from "js-md5";
import enableMFA from "../login/component/enableMFA";
import validationMFA from "../login/component/validationMFA";
export default {
  components: {
    enableMFA,
    validationMFA,
  },
  data() {
    var validateOldPass = (rule, value, callback) => {
      if (this.modifyPwType != "autoRegister") {
        if (value === "") {
          // callback(new Error("请输入旧密码"));
          callback(new Error(this.$t("label.account.EnterOldPass")));
        } else {
          callback();
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        // callback(new Error("不能为空"));
        callback(new Error(this.$t("label.account.CannotBeEmpty")));
      } else if (value.length < parseInt(this.minPswLength)) {
        callback(
          new Error(this.$t("label.account.MinLength") + this.minPswLength)
        );
      } else if (!this.pswComplexReq.test(value)) {
        callback(new Error(this.pswComplexReqRules));
      } else {
        callback();
      }
    };
    var validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.Confirm.password")));
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t("label.account.pw.error.text")));
      } else {
        callback();
      }
    };
    return {
      modifyPwType: "", //改密码的方式，包括firLogToChanPw(首次登录)、passwordExpire(密码过期)、autoRegister(注册用户)，当为autoRegister时，可以不传递旧密码
      minPswLength: "", // 密码最小长度
      pswComplexReq: "", // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号 //正则
      pswComplexReqRules: "", // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号
      loginName: "", //用户名
      name: "",
      ruleForm: {
        oldPass: "", // 旧密码
        password: "", // 新密码
        checkPass: "", // 二次确认新密码
      },
      rules: {
        oldPass: [
          { required: true, validator: validateOldPass, trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        checkPass: [
          { required: true, validator: validateCheckPass, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.loginName = this.$route.query.username;
    this.modifyPwType = this.$route.query.modifyPwType;
    // this.href = this.$route.query.JumpUrl;
    this.accessToken = this.$route.query.accessToken;
    this.getPasswordPolicyWithBind();
  },
  mounted() {
    // 隐藏加载框
    document.getElementById("lightning-loading").style.display = "none";
  },
  methods: {
    // 获取密码策略
    getPasswordPolicyWithBind() {
      const header = {
        accessToken: this.accessToken,
      };
      loginApi.getPasswordPolicyWithBind({}, header).then((res) => {
        // 密码最小长度
        this.minPswLength = res.data.data.minPswLength;
        // this.minPswLength = "5";
        // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号
        if (res.data.data.pswComplexReq == "0") {
          this.pswComplexReq = /./;
        } else if (res.data.data.pswComplexReq == "1") {
          this.pswComplexReq = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{0,}$/;
          this.pswComplexReqRules = this.$t("label.account.Alphanumeric");
        } else if (res.data.data.pswComplexReq == "2") {
          this.pswComplexReq = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,}$/;
          this.pswComplexReqRules = this.$t(
            "label.account.UpperLowerlAphanumeric"
          );
        } else if (res.data.data.pswComplexReq == "3") {
          this.pswComplexReq =
            /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{0,}/;
          this.pswComplexReqRules = this.$t(
            "label.account.UpperLowerlAphanumeric.character"
          );
        }
      });
    },

    // 登录后修改密码
    modifyUserPw() {
      const parameter = {
        // 旧密码(md5加密)，如果是注册用户首次登录改密码，无须传旧密码
        oldPassword:
          this.modifyPwType == "autoRegister" ? "" : md5(this.ruleForm.oldPass),
        // 新密码(md5加密)，如果是注册用户首次登录改密码，新密码无须md5加密
        newPassword:
          this.modifyPwType == "autoRegister"
            ? this.ruleForm.password
            : md5(this.ruleForm.password), //	新密码(MD5加密之后)
        // 更改密码的类型，如果是注册用户首次登录，传autoRegister即可，其他时候不需要传
        modifyPwType: this.modifyPwType == "autoRegister" ? "autoRegister" : "",
      };
      const header = {
        accessToken: this.accessToken,
      };
      loginApi
        .modifyUserPw(parameter, header)
        .then((res) => {
          if (res.data.result) {
            this.$message({
              showClose: true,
              message: res.data.returnInfo,
              type: "success",
              duration: 6000,
            });
            if (res.data.data.status) {
              // 有status时 分几种状态
              if (res.data.data.status == "mfabound") {
                // 去绑定MFA
                this.$refs.enableMFA.open(res);
              } else if (res.data.data.status == "mfavalidate") {
                // mfavalidate 为MFA输入验证码
                this.$refs.validationMFA.open(res);
              }
            } else {
              // 没有status
              let href = "./#/?binding=" + res.data.data.accessToken;
              window.location.replace(href);
            }
          } else {
            this.$message({
              showClose: true,
              message: res.data.returnInfo,
              type: "error",
              duration: 6000,
              // center: true,//居中
            });
          }
        })
        .catch(() => {});
    },
    // 验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 修改
          this.modifyUserPw();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__error {
  font-size: 16px !important;
  line-height: 1;
  padding-top: 4px;
  top: -12%;
  left: 15px;
  background: #fff;
  padding: 0px 7px;
  color: #f11b21;
}
::v-deep .el-form-item.is-error .el-input__inner {
  border: 1px solid #f11b21;
}
::v-deep .el-input__inner {
  /* 输入框 */
  font-size: 16px;
  border-radius: 4px 4px 4px 4px;
  height: 60px;
}
.el-form {
  .el-form-item {
    width: 740px;
    height: 57px;
    ::v-deep .el-input__inner {
      height: 56px;
    }
  }
}
.CP {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  font-size: 18px;
  color: #333;
  background-image: linear-gradient(
    180deg,
    #f6f7f9 0%,
    rgba(245, 246, 249, 0.48) 100%
  );

  .CPlogo {
    width: 804px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 38px 0;
    img {
      width: 500px;
    }
    span {
      font-size: 34px;
      color: #333;
      margin-top: 100px;
    }
  }
  .CPform {
    background: #fff;
    width: 804px;
    padding: 30px 32px;
    & > div {
      width: 740px;
      margin-bottom: 27px;
      div {
        margin-top: 24px;
      }
    }
    & > span {
      font-size: 16px;
      color: #f11b21;
    }
    .Btn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .el-button {
        min-width: 104px;
        /*height: 41px;*/
        background: #2d6cfc;
        border-radius: 4px;
      }
    }
  }
  .CPform1 {
    background: #fff;
    width: 804px;
    padding: 30px 32px;
    & > div {
      margin-bottom: 27px;
    }
    .Btn {
      display: flex;
      justify-content: space-between;
      margin-top: 96px;
      .el-button {
        min-width: 104px;
        height: 41px;
        background: #2d6cfc;
        border-radius: 4px;
      }
    }
  }
}
</style>
